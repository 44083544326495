<template>
  <div>
    <div class="footer">
      <div class="bgImg2" :style="{ height: scrollerHeight }">
        <div class="contain">
          <div style="text-align: center; position: absolute; top: 20%">
            <img
              src="../../assets/ReactHtmlImg/logo.png"
              alt=""
              class="imgLogo2"
            />
          </div>
          <div style="position: absolute; bottom: 10%; width: 70%">
            <div class="nameFooter">
              <div class="left">
                <p style="padding-bottom: 10px">北京贝湾教育科技有限公司</p>
                <p style="padding-bottom: 10px">湖南贝湾教育科技有限公司</p>
                <p class="text">
                  <img
                    src="../../assets/ReactHtmlImg/icon1.png"
                    alt=""
                    class="icon"
                  />
                  <span>北京市朝阳区工体北路甲2号盈科中心A座</span>
                </p>
                <p class="text">
                  <img
                    src="../../assets/ReactHtmlImg/icon1.png"
                    alt=""
                    class="icon"
                  />
                  <span
                    >长沙市开福区湘江北路1500号北辰时代广场A1写字楼</span
                  >
                </p>
                <p class="text">
                  <img
                    src="../../assets/ReactHtmlImg/icon2.png"
                    alt=""
                    class="icon"
                  />
                  <span>www.lumibayedu.com</span>
                </p>
              </div>
              <img
                src="../../assets/ReactHtmlImg/QR code.png"
                alt=""
                class="code"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerBottom",
  data() {
    return {
      scrollerHeight: "",
    };
  },
  mounted() {
    this.scrollerHeight =
      parseInt(document.body.clientWidth * (297.0 / 210.0) - 11) + "px";
  },
};
</script>